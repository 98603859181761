// import React, { useState } from "react";
import moment from "moment/min/moment-with-locales";
import { GetState } from "./store";
import _ from "lodash";
const format = "DD-MM-YYYY";
export const dateFormat = (date) => moment(date).format(format);
export const ToTime = (date) => {
  date = moment(date, "HH:mm");
  date = moment(date).format("HH:mm");
  return date;
};
export const startOf = (unit) => moment().startOf(unit).toDate();
export const endOf = (unit) => moment.utc().endOf(unit).toDate();

// export const StartEndOff = (unitOff) => {
//   const dates = {
//     startOf: useState(moment().startOf(unitOff).toDate()),
//     endOf: useState(moment().endOf(unitOff).toDate()),
//   };
//   return dates;
// };
export const startEndOffDates = (unitOff) => {
  const dates = {
    startOf: moment().startOf(unitOff).toDate(),
    endOf: moment().endOf(unitOff).toDate(),
  };
  return dates;
};
export const startEndOffDatesFormatted = (unitOff) => {
  const dates = {
    startOf: moment().startOf(unitOff).format(format),
    endOf: moment().endOf(unitOff).format(format),
  };
  return dates;
};
export const currentNextDates = (number, period) => {
  const duration = {
    current: moment().toDate(),
    next: moment().add(number, period).toDate(),
  };
  return duration;
};
// generate new employee code
export const newEmployeeCode = () => {
  const employees = Object.values(GetState().employees);
  if (employees.length) {
    let newCode = +1 + _.last(employees).EmployeeCode.match(/\d+/)[0]++;
    return newCode;
  }
  return 1;
};
export const nextPrevDate = (number, period) =>
  moment().add(number, period).toDate();
