import * as type from "./types";
export const SettingWpsConfig = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_SETTING_WPS_CONFIG:
      return { ...action.payload };
    default:
      return state;
  }
};
export const PayrollListForWps = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_PAYROLL_LIST_FOR_WPS:
      return { ...action.payload };
    default:
      return state;
  }
};
export const PayInGroupsMaster = (state = [], action) => {
  switch (action.type) {
    case type.FETCH_PAY_IN_GROUP_MASTER:
      return { ...action.payload };
    default:
      return state;
  }
};
